import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "account-container" }
const _hoisted_2 = { class: "account-content" }
const _hoisted_3 = { class: "subLabel" }
const _hoisted_4 = { class: "loginform text-left" }
const _hoisted_5 = { class: "ar-field" }
const _hoisted_6 = { class: "formlabel" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_9 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_10 = { class: "ar-field" }
const _hoisted_11 = { class: "formlabel" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_14 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_15 = { class: "ar-field" }
const _hoisted_16 = { class: "formlabel" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_19 = {
  key: 1,
  class: "ar-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('header.account')), 1),
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('editMode')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("form", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('form.firstName')), 1),
            _withDirectives(_createElementVNode("input", {
              name: "firstName",
              type: "text",
              placeholder: _ctx.$t('form.firstName'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userform.firstName) = $event))
            }, null, 8, _hoisted_7), [
              [_vModelText, _ctx.userform.firstName]
            ]),
            (_ctx.errorClass('string', _ctx.userform.firstName)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('string', _ctx.userform.firstName)=='toShort')
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('error.too_short')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('form.lastName')), 1),
            _withDirectives(_createElementVNode("input", {
              name: "lastName",
              type: "text",
              placeholder: _ctx.$t('form.lastName'),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userform.lastName) = $event))
            }, null, 8, _hoisted_12), [
              [_vModelText, _ctx.userform.lastName]
            ]),
            (_ctx.errorClass('string', _ctx.userform.lastName)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('string', _ctx.userform.lastName)=='toShort')
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('error.too_short')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('form.username')), 1),
            _withDirectives(_createElementVNode("input", {
              name: "email",
              type: "email",
              placeholder: _ctx.$t('form.username'),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userform.username) = $event))
            }, null, 8, _hoisted_17), [
              [_vModelText, _ctx.userform.username]
            ]),
            (_ctx.errorClass('email', _ctx.userform.username)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('email', _ctx.userform.username)=='wrongMail')
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t('error.wrongMail')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_Button, {
          text: _ctx.$t('button.save'),
          waitForResponse: _ctx.waitForResponse,
          onHandleClicked: _ctx.handleClickSubmitEdit
        }, null, 8, ["text", "waitForResponse", "onHandleClicked"]),
        _createVNode(_component_Button, {
          class: "btn-cancel",
          text: _ctx.$t('button.cancel'),
          dense: true,
          waitForResponse: _ctx.waitForResponse,
          onHandleClicked: _ctx.handleGoBack
        }, null, 8, ["text", "waitForResponse", "onHandleClicked"])
      ])
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error,
      aboveBottomMenu: true
    }, null, 8, ["show", "text", "error"])
  ]))
}