
import { defineComponent } from 'vue'
import { UserController } from '@/controller';
import { /* FixedHeaderTop, */ PageHeader, Snackbar  } from '@/components/';
import { Button } from '@/ui';

export default defineComponent({
    name: "EditProfile",
    components: { Snackbar, /* FixedHeaderTop, */ Button, PageHeader },
    data(){
        return{
            user: {} as any,
            abos: [] as Array<any>,
            userform:{
                uid: 0,
                firstName: '',
                lastName: '',
                username: ''
            },
            icons:{
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            validateForm: false,
            waitForResponse: false,
            snackbar: {
                show: false,
                text: '',
                error: false
            },
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.fetchData();
    },
    methods: {
        handleGoBack(){
            this.$router.push('/account')
        },
        /*------------------USER------------------*/
        async fetchData(){
            this.user = await UserController.user;

            if(this.user){
                this.userform = {
                    uid: this.user.uid,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    username: this.user.username
                }
            }
        },
        async onEditUser(){
            this.waitForResponse = true
            let res = await UserController.editUser(this.userform)
            if(res.data.success){
                this.user = {
                    uid: this.userform.uid,
                    username: this.userform.username,
                    firstName: this.userform.firstName,
                    lastName: this.userform.lastName,
                    favorits: this.user.favorits,
                    recentMedSessions: this.user.recentMedSessions,
                    abo: this.user.abo,
                    freeSessionsLeft: this.user.freeSessionsLeft
                }
                this.handleShowSnackbar(this.$t('snackbar.userSaved'), false)
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.waitForResponse = false
        },
        /*-------------- Validation -----------------*/
        errorClass(fieldType : any, input : any){
            if(this.validateForm){
                if(input == ""){
                    return 'required'
                } else if(fieldType == 'email'){
                    var re = /\S+@\S+\.\S+/;
                    if(!re.test(input)){
                        return 'wrongMail'
                    }
                } else if( fieldType == 'string'){
                    if(input.length < 3){
                        return 'toShort'
                    }
                }
            }
        },
        onValidateEditForm(){
            //NOL: Quick&Dirty Validation
            //check valid mail
            var re = /\S+@\S+\.\S+/;
            if(!re.test(this.userform.username)){
                return false
            } 
            //check other fields
            if(this.userform.firstName.length >=3 && this.userform.lastName.length >=3 && this.userform.username.length >=5){
                return true
            }
            return false
        },
        handleClickSubmitEdit(){
            this.validateForm = true
            if(this.onValidateEditForm()){
                this.onEditUser()
                this.validateForm = false;

            } else {
                console.log('invalid form')
            }
        },
        /*--------------General-----------------*/
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
                //this.$router.push('/account')
            }, 5000)
        }
    }
})
